import * as React from 'react'
import Layout from '../components/layout'
import ContactForm from '../templates/contact-form'
import ContactFooter from '../templates/contact-footer'
import Seo from '../components/seo'

const Contact = () => {
  return (
    <Layout >
      <Seo title='Contact Me' description='Get in touch to book your crystal Reiki session, or Ultimate Goddess healing including blessing &amp; rituals, with a qualified Crystal Reiki practitioner in Altrincham'/>
      <div>
        <div className='headingStyles'>
          <h1>Schedule a Session:</h1>
        </div>
        <div className='formContainer'>
          <div className='fees contactPink'>
            <p>Contact details:</p>
            <p>mobile/Whatsapp: 07876 794423</p>
            <p className='marginBottom'>email: healingcrystalsreiki@gmail.com</p>
            <p className='offers marginBottom'>or send me a message:</p>
          </div>
        <ContactForm />
        </div>
      </div>
      {/* <div>
        <div style={styles.headingStyles}>
          <h1>Location</h1>
        </div>
        <div style={styles.fees}>
          <p>Altrincham, Cheshire</p>
          <div>MAP</div>
        </div>
      </div> */}
      <div>
        <div className='headingStyles'>
          <h1>&nbsp;</h1>
        </div>
        <div>
          <ContactFooter />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
